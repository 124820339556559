import React from "react"
// import firebase from "firebase/compat/app"
import firebase from "gatsby-plugin-firebase"

export default class ContactForm extends React.Component {
  constructor() {
    super()
    this.onSubmit = this.onSubmit.bind(this)
    this.state = {
      isSubmitted: false,
    }
  }

  componentDidMount() {

    //URLパラメータを文字列で取得(?含む)
    let urlParamStr = window.location.search
    if (urlParamStr) {
      //?を除去
      urlParamStr = urlParamStr.substring(1)

      let params = {}

      //urlパラメータをオブジェクトにまとめる
      urlParamStr.split('&').forEach(param => {
        const temp = param.split('=')
        //pramsオブジェクトにパラメータを追加
        params = {
          ...params,
          [temp[0]]: temp[1]
        }
      })
      //題名自動入力
      document.getElementById("subject").value = decodeURI(params.subject)
    }
  }


  onSubmit(e) {
    // NGワード登録
    const ngwords = [
      "突然のメールのご無礼をお許しください",
      "お読みいただき",
      "ご検討のほどよろしくお願い",
      "ご検討の程よろしくお願い",
      "ご検討のほど宜しくお願い",
      "ご検討の程宜しくお願い",
      "顧問名鑑",
      "株式会社ライトアップ",
      "パーソルテクノロジースタッフ",
      "ご質問・ご不明点等ございましたら",
    ]
    // 外国語禁止
    const foreign = /[\u{3000}-\u{301C}\u{3041}-\u{3093}\u{309B}-\u{309E}]/mu
    // ページ遷移防止
    e.preventDefault()

    // NGワード判定
    const result = ngwords.filter(word => e.target.message.value.includes(word))
    if (result.length) {
      alert("問い合わせフォーム営業であると判定されました。「よろしくお願いします」を「お願いします」に変更してください。")
    } else if (!foreign.test(e.target.message.value)) {
      alert("不正な入力を検知しました。")
    } else {
      // 通常の送信処理
      if (ngwords.indexOf) { }
      let data = {}
      data.name = e.target.name.value
      data.email = e.target.email.value
      data.company = e.target.company.value
      data.tel = e.target.tel.value
      data.subject = e.target.subject.value
      data.message = e.target.message.value
      data.ua = window.navigator.userAgent
      let sendMail = firebase.functions().httpsCallable('sendMail');
      sendMail(data)

      // // Hubspotにデータ送信
      // var xhr = new XMLHttpRequest()
      // var url = 'https://api.hsforms.com/submissions/v3/integration/submit/21225247/1de86154-3d30-479c-a9ec-b83bad9da2ae'

      // var date = new Date()
      // var unixtime = date.getTime()

      // // Hubspotに送信するJSONデータ:
      // var hubspotData = {
      //   "submittedAt": unixtime,
      //   "fields": [
      //     {
      //   "objectTypeId": "0-1",
      //       "name": "email",
      //       "value": e.target.email.value
      //     },
      //     {
      //   "objectTypeId": "0-1",
      //       "name": "firstname",
      //       "value": e.target.name.value
      //     },
      //     {
      //       "objectTypeId": "0-1",
      //           "name": "phone",
      //           "value": e.target.tel.value
      //     },
      //     {
      //       "objectTypeId": "0-1",
      //           "name": "message",
      //           "value": e.target.message.value
      //     }
      //   ],
      //   "context": {
      //     // "hutk": ':hutk', // include this parameter and set it to the hubspotutk cookie value to enable cookie tracking on your submission
      //     "pageUri": "isecj.jp/contact",
      //     "pageName": "問い合わせページ"
      //   },
      // }

      // var final_data = JSON.stringify(hubspotData)

      // xhr.open('POST', url);
      // // Sets the value of the 'Content-Type' HTTP request headers to 'application/json'
      // xhr.setRequestHeader('Content-Type', 'application/json');
  
      // xhr.onreadystatechange = function() {
      //     if(xhr.readyState === 4 && xhr.status === 200) { 
      //         alert(xhr.responseText); // Returns a 200 response if the submission is successful.
      //     } else if (xhr.readyState === 4 && xhr.status === 400){ 
      //         alert(xhr.responseText); // Returns a 400 error the submission is rejected.          
      //     } else if (xhr.readyState === 4 && xhr.status === 403){ 
      //         alert(xhr.responseText); // Returns a 403 error if the portal isn't allowed to post submissions.           
      //     } else if (xhr.readyState === 4 && xhr.status === 404){ 
      //         alert(xhr.responseText); //Returns a 404 error if the formGuid isn't found     
      //     }
      //    }
      // // Sends the request   
      // xhr.send(final_data)

      // フォームのデータ初期化
      e.target.name.value = ""
      e.target.email.value = ""
      e.target.company.value = ""
      e.target.tel.value = ""
      e.target.subject.value = ""
      e.target.message.value = ""
      e.target.value = ""
      this.setState({ isSubmitted: true })
    }

    typeof window !== "undefined" &&  window.gtag("event", "submit", {
      send_to : "UA-162408467-1",
      event_category : "contactform", 
      event_label : "contact", 
      value : "1"
    })
  }

  render() {
    let contactForm
    if (this.state.isSubmitted) {
      contactForm = (
        <div className='contact-submit-message'>
          <h3>送信完了</h3>
          <p>メッセージは正常に送信されました。</p>
          <p>数営業日中に担当者より返信が届きます。</p>
        </div>
      )
    } else {
      contactForm = (
        <div className='contact-submit-message'>
          <p>
            <input type="submit" value="送信" className="submit_button" />
          </p>
        </div>
      )
    }
    return (
      <div className='contact-form'>
        <form onSubmit={this.onSubmit}>
          <p>
            <input
              type="text"
              name="name"
              placeholder="氏名（必須）"
              maxlength="100"
              required
              />
          </p>
          <p>
            <input
              type="email"
              name="email"
              placeholder="メールアドレス（必須）"
              maxlength="100"
              required
              />
          </p>
          <p>
            <input
              type="text"
              name="company"
              placeholder="会社名（必須）"
              maxlength="100"
              required
              />
          </p>
          <p>
            <input
              type="tel"
              name="tel"
              placeholder="電話番号"
              maxlength="100"
              />
          </p>
          <p>
            <input
              type="text"
              name="subject"
              id="subject"
              placeholder="題名"
              maxlength="100"
            />
          </p>
          <p>
            <label htmlFor="message">
              お問い合わせ内容（必須）
            </label>
            <textarea
              rows="15"
              name="message"
              required
            ></textarea>
          </p>
          {contactForm}
        </form>

      </div>
    )
  }
}