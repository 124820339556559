import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import ContactForm from "../components/contactform"

const Contact = ({ data, location }) => {


    const siteTitle = data.site.siteMetadata?.title || `Title`

    return (
        <Layout location={location} title={siteTitle}>
            <Seo title="お問い合わせ" />
        <div className="post-header">
            <h1>お問い合わせ</h1>
        </div>
        <div className="post-body">
            <p>下記フォーマットにご記入いただき、内容をご確認のうえ、送信してください。</p>
            <ContactForm />

        </div>
        </Layout>
    )
}

export default Contact

export const contactQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
